import { Component, Vue } from "vue-property-decorator";
import { IBasicUser } from "@/interfaces/IBasicUser";
import { formatInstructorName, formatStudentName } from "@/utils/NameUtil";

@Component
export default class BaseMixin extends Vue {
  protected isWhite(color: string): boolean {
    return Boolean(color.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i));
  }

  protected instructorCustomLabel(instructor: IBasicUser): string {
    return formatInstructorName(instructor.firstName, instructor.lastName);
  }

  protected studentCustomLabel(student: IBasicUser): string {
    return formatStudentName(student.firstName, student.lastName);
  }
}
