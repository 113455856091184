


















import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";

@Component({
  components: { HintMessage, FscSimpleCard },
})
export default class Hint extends Vue {
  public name = "Hint";

  @Prop({ default: () => "hint" })
  protected id!: any;

  @Prop({ default: () => true })
  protected visible!: any;

  protected onShow(): void {
    this.visible = true;
  }

  protected onHide(): void {
    this.visible = false;
  }
}
