export const formatStudentName = (firstName?: string | null, lastName?: string | null) => {
  if (firstName && lastName) return `${lastName} ${firstName}`;

  return "";
};

export const formatInstructorName = (firstName?: string | null, lastName?: string | null) => {
  if (firstName || lastName) return `${firstName ?? ""} ${lastName ?? ""}`;

  return "";
};
