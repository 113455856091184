<template>
  <span>
    <span class="mr-12">
      <font-awesome-icon :icon="['fas', 'lightbulb-on']" />
    </span>
    <span class="font-weight-bold">
      {{ title }}
    </span>
  </span>
</template>

<script>
export default {
  name: "HintMessage",
  props: {
    msg: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    title() {
      return this.msg || this.$t("automatic_payment.hint");
    },
  },
};
</script>
